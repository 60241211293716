import { getStrapiImage } from '@/lib/api'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface AboutUs {
  rs: SocialMedia
  data: Apropos
}

const About: React.FC<AboutUs> = ({ data, rs }) => {
  return (
    <Stack sx={{ bgcolor: 'accent.main', my: 6, py: 6 }}>
      <Container>
        <Box
          sx={{
            bgcolor: 'background.paper',
            mt: 22,
            p: { xs: 2, md: 8 },
            pt: 22,
            borderRadius: 6,
          }}
        >
          <Grid container sx={{ py: 4 }}>
            <Grid xs={12} item md={6}>
              <Stack
                sx={{
                  width: '80%',
                  height: 250,
                  position: 'relative',
                  borderRadius: 6,
                  overflow: 'hidden',
                  boxShadow: '0 0 15px 0 rgba(0,0,0,0.4)',
                  mt: '-180px',
                }}
              >
                <Image
                  alt="Updev Challenge"
                  src={getStrapiImage(data?.media)}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  // placeholder="blur"
                  // blurDataURL={getStrapiImagePlaceholder(data?.media)}
                  priority
                />
              </Stack>
            </Grid>
            <Grid xs={12} item md={6}>
              <Stack direction="row" justifyContent="space-between">
                <Stack alignItems="center" sx={{ overflow: 'hidden' }}>
                  <Typography
                    variant="h1"
                    fontWeight={800}
                    color="accent.main"
                    sx={{ fontSize: { xs: '5rem', md: '6rem' } }}
                  >
                    {data?.sub}
                  </Typography>
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    sx={{ mt: '-70px' }}
                  >
                    {data?.titre}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <a
                    href={rs?.facebook}
                    target="_blank"
                    title="Facebook of uptodate developers"
                    rel="noopener noreferrer"
                  >
                    <IconButton>
                      <FacebookIcon color="primary" aria-label="facebook" />
                    </IconButton>
                  </a>
                  <a
                    href={rs?.twitter}
                    target="_blank"
                    title="Twitter of uptodate developers"
                    rel="noopener noreferrer"
                  >
                    <IconButton>
                      <TwitterIcon color="primary" aria-label="twitter" />
                    </IconButton>
                  </a>
                  <a
                    href={rs?.linkedin}
                    target="_blank"
                    title="LinkedIn of uptodate developers"
                    rel="noopener noreferrer"
                  >
                    <IconButton>
                      <LinkedInIcon color="primary" aria-label="linkedIn" />
                    </IconButton>
                  </a>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Typography color="text.secondary">
            <ReactMarkdown>{data?.description}</ReactMarkdown>
          </Typography>
        </Box>
      </Container>
    </Stack>
  )
}

export default About
